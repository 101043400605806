<template>
  <div class="DarkWhite">
    <v-data-table
      :headers="headers"
      :items="rsList"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :footer-props="footerProps"
      class="elevation-1 pt-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            style="max-width: 300px"
            v-model="searchText"
            append-icon="mdi-magnify"
            placeholder="Search RS/IAR/RIS/ICS/PAR number"
            color="green darken-2"
            class="mr-2 mt-2"
            dense
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-autocomplete
            color="green"
            v-model="userID"
            item-text="name"
            item-value="UserID"
            :items="userList"
            dense
            hide-details
            class="mr-2 mt-2"
            style="max-width:200px;"
            label="Filter By User"
          >
          </v-autocomplete>
        </v-toolbar>
      </template>
      <template v-slot:[`item.DateReturned`]="{ item }">
        {{ formatDate(item.DateReturned) }}
      </template>
      <template v-slot:[`item.Other_Actions`]="{ item }">
        <span class="text-no-wrap">
        <v-btn
          color="amber darken-2"
          class="mr-2"
          dark
          outlined
          elevation="2"
          x-small
          @click="dispense(item)"
          v-if="item.IsCancelled == 0 && item.NotedStatusID == 7"
        >
          Transfer
        </v-btn>
        <span class="grey--text" v-else-if="item.IsCancelled == 0 && item.NotedStatusID != 7" >For Approval</span>
        <span class="red--text" v-else >Cancelled</span>
        </span>
      </template>
      <template v-slot:[`item.IsPrinted`]="{ item }">
        <v-checkbox v-model="item.IsPrinted" color="header" @change="selectPrinted(item)"></v-checkbox>
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
          <v-btn
            color="blue darken-2"
            class="mr-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="viewRS(item)"
          >
            View
          </v-btn>
          <v-btn
            color="red darken-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="printRS(item)"
            v-if="item.IsCancelled == 0"
          >
            Print
          </v-btn>
        </span>
      </template>
    </v-data-table>
    <RSModal :rsData="rsData" />
    <ProcessModal :processData="processData" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  components: {
    RSModal: () => import("./ViewRS.vue"),
    ProcessModal: () => import("./ProcessRS.vue"),
  },
  props: {
    fiscalYear: null
  },
  data: () => ({
    searchText: "",
    searchTimeout: null,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    headers: [
      {
          text: 'RS CODE',
          align: 'start',
          sortable: false,
          value: 'RSCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'IAR CODE',
          align: 'start',
          sortable: false,
          value: 'IARCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'RIS CODE',
          align: 'start',
          sortable: false,
          value: 'RISCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'ICS/PAR CODE',
          align: 'start',
          sortable: false,
          value: 'ICSPARCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Returned By',
          align: 'start',
          sortable: false,
          value: 'ReturnBy',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Returned',
          align: 'start',
          sortable: false,
          value: 'DateReturned',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: 'center', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
      isLoading: false,
      rsList: [],
      rsData: [],
      processData: [],
      userList: [],
      userID: 0,
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    fiscalYear: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    userID: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  created() {
    if(this.$store.state.user.roleID == 6 || this.$store.state.user.roleID == 15 || this.$store.state.user.roleID == 16 || this.$store.state.user.roleID == 17 || this.$store.state.user.roleID == 20){
      this.headers = [
      {
          text: 'RS CODE',
          align: 'start',
          sortable: false,
          value: 'RSCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'IAR CODE',
          align: 'start',
          sortable: false,
          value: 'IARCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'RIS CODE',
          align: 'start',
          sortable: false,
          value: 'RISCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'ICS/PAR CODE',
          align: 'start',
          sortable: false,
          value: 'ICSPARCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Returned By',
          align: 'start',
          sortable: false,
          value: 'ReturnBy',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Returned',
          align: 'start',
          sortable: false,
          value: 'DateReturned',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'Other_Actions',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Printed?',
          align: 'center',
          sortable: false,
          value: 'IsPrinted',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: 'center', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ];
    }
    else{
      this.headers = [
      {
          text: 'RS CODE',
          align: 'start',
          sortable: false,
          value: 'RSCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'IAR CODE',
          align: 'start',
          sortable: false,
          value: 'IARCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'RIS CODE',
          align: 'start',
          sortable: false,
          value: 'RISCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'ICS/PAR CODE',
          align: 'start',
          sortable: false,
          value: 'ICSPARCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Returned By',
          align: 'start',
          sortable: false,
          value: 'ReturnBy',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Returned',
          align: 'start',
          sortable: false,
          value: 'DateReturned',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: 'center', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ];
    }
  },
  mounted() {
    this.eventHub.$on("closeViewRS", () => {
      this.initialize();
    });
    this.eventHub.$on("closeProcessRS", () => {
      this.initialize();
    });
    this.getUsers();
  },
  beforeDestroy() {
    this.eventHub.$off("closeViewRS");
    this.eventHub.$off("closeProcessRS");
  },
  methods: {
    viewRS(item) {
      this.rsData = item;
    },
    dispense(item) {
      this.processData = item;
    },
    printRS(item) {
      this.link = this.apiUrl + "/rs/report/" + item.RSID;
      window.open(this.link);
    },
    initialize() {
      this.loading = true;
      if(this.fiscalYear != null){
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("tab", this.tab);
      data.append("searchText", this.searchText);
      data.append("FiscalYearID", this.fiscalYear);
      data.append("UserID", this.userID);
      this.axiosCall("/getRSList", "POST", data).then((res) => {
        this.loading = false;
        this.rsList = res.data.data.result;
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
      });
      }
    },
    getUsers() {
      this.axiosCall("/getAllPersonsAccountable", "GET").then((res) => {
        this.userList = [{UserID: 0, name: "All"},...res.data.data];
      });
    },
        selectPrinted(item) {
      let data = new FormData();
      data.append("id", item.RSID);
      data.append("table", 'rs');
      data.append("column", 'RSID');
      data.append("IsPrinted", item.IsPrinted ? 1 : 0);
      this.axiosCall("/setPrinted", "POST", data).then((res) => {
        if(res.data.status){
          let msg = item.IsPrinted ? 'printed.' : 'not printed.';
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "success";
          this.fadeAwayMessage.message = item.RSCODE + " was successfully set to " + msg;
          this.fadeAwayMessage.header = "System Message";
        }
        else{
          item.IsPrinted = !item.IsPrinted;
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.message = "Something went wrong!";
          this.fadeAwayMessage.header = "System Message";
        }
      });
    },
  },
};
</script>